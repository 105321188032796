.react-flow__attribution {
  display: none !important;
}

.react-flow {
  /* Custom Variables */
  --xy-theme-selected: #F57DBD;
  --xy-theme-hover: #C5C5C5;
  --xy-theme-edge-hover: #000000;
  --xy-theme-color-focus: #E8E8E8;

  /* Built-in Variables see https://reactflow.dev/learn/customization/theming */
  --xy-node-border-default: 1px solid #EDEDED;

  --xy-node-boxshadow-default: 
    0px 3.54px 4.55px 0px #00000005, 
    0px 3.54px 4.55px 0px #0000000D, 
    0px 0.51px 1.01px 0px #0000001A;

  --xy-node-border-radius-default: 12px;

  --xy-handle-background-color-default: #ffffff;
  --xy-handle-border-color-default: #AAAAAA;

  --xy-edge-label-color-default: #505050;
}

.react-flow.dark { 
  --xy-node-boxshadow-default: 
  0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.05), /* light shadow */
  0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.13), /* medium shadow */
  0px 0.51px 1.01px 0px rgba(255, 255, 255, 0.2);  /* smallest shadow */  
  --xy-theme-color-focus: #535353;
}

/* Customizing Default Theming */

.react-flow__node {
  box-shadow: var(--xy-node-boxshadow-default);
  border-radius: var(--xy-node-border-radius-default);
  /* background-color: var(--xy-node-background-color-default); */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: null;
  font-size: 12px;
  flex-direction: column;
  /* border: var(--xy-node-border-default);
  color: var(--xy-node-color, var(--xy-node-color-default)); */
}

.react-flow__node.selectable:focus {
  box-shadow: null;
}

.react-flow__node.selectable:focus:active {
  box-shadow: null;
}

.react-flow__node.selectable:hover, 
.react-flow__node.draggable:hover {
  border-color: var(--xy-theme-hover);
}

.react-flow__node.selectable.selected {
  box-shadow: null;
}

.react-flow__node-group {
  background-color: rgba(207, 182, 255, 0.4);
  border-color: #9E86ED;
}

.react-flow__edge-path {
  stroke-width: 2;
  stroke: #b1b1b7;
}

.react-flow__edge.selectable:hover .react-flow__edge-path,
.react-flow__edge.selectable.selected .react-flow__edge-path {
  stroke: var(--xy-theme-edge-hover);
}

/* 悬停效果 */
.react-flow__edge:hover .react-flow__edge-path {
  stroke-width: 3;
  stroke: #555;
}

.react-flow__handle {
  background-color: var(--xy-handle-background-color-default);
}

.react-flow__handle.connectionindicator:hover {
  pointer-events: all;
  border-color: var(--xy-theme-edge-hover);
  background-color: white;
}

.react-flow__handle.connectionindicator:focus , 
.react-flow__handle.connectingfrom,
.react-flow__handle.connectingto {
  border-color: var(--xy-theme-edge-hover);
}

.react-flow__node-resizer {
  border-radius: 0;
  border: none;
}

.react-flow__resize-control.handle {
  background-color: #ffffff;
  border-color: #9E86ED;
  border-radius: 0;
  width: 5px;
  height: 5px;
}

.react-flow__node, .react-flow__minimap-node {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  transform: scale(1);
}

.react-flow__node.flow-hidden, .react-flow__minimap-node.flow-hidden {
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
}

.react-flow__edge {
  transition: all 0.3s ease-in-out;
}

.react-flow__edge.flow-hidden {
  opacity: 0;
  stroke-dasharray: 5;
  stroke-opacity: 0;
  pointer-events: none
}

.react-flow__node-custom {
  transition-property: opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
